@charset "utf-8";



// Our variables
// We have to improve this:
// Names of color variables have to be changed to non speaking keys.
// $grey-color is bad, better to name the variable by its usage, e.g. $bg-color-table
// 
$base-font-family: Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #ffffff;
$bg-color-table:   #cccccc; //== grey-color-light
$brand-color:      #d1121c; //old: #2a7ae2;
$visited-color:    #c1121c; //old: darken($brand-color, 15%)
$menu-border-radius: 5px 5px 0 0;  //border of the menu icons

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  #c1121c; //old: darken($grey-color, 25%);

$post-link-color:  #c1121c; //RAL 3000
$icon_color:       white;

$div-bgcolor:      rgba(0, 135, 255, 0.24);

// Text background color for new or updated text
$new-color:        rgb(148, 255, 0);
$update-color:     rgba(148, 255, 0, 0.25);
$update-color_del: rgba(255, 0, 0, 0.25);
$update-color_ins: rgba(148, 255, 0, 0.25);

// Text color for inportant notice
$alarm-bg-color:   red;
$alarm-color:      white;

// Height of the header
$header_height:    200px;

// Width of the content area
$content-width:    1280px + $spacing-unit * 2 ;

$on-smartphone:    450px; 
$on-palm:          800px;
$on-laptop:        800px;


// Using media queries with like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "events",
		"svg-icons"
;
